
import { Component, Prop, Vue } from "vue-property-decorator";
import QuestionText from "./QuestionText.vue";
import AdminOptions from "./AdminOptions.vue";
import MultiDocument from "@/components/MultiDocument.vue";
import utils from "@/stuff/Utils";
import store from "@/store/store";
import { SurveyQuestion } from "@/model/SurveyQuestion";
import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";
import { SurveyResponse } from "@/model/SurveyResponse";
import { ResponseItem } from "@/model/ResponseItem";
import { Document as ModelDocument } from "@/model/Document";
import { ResponseStatus, SurveyResponseOtherOptionType, UserRole } from "@/model/Enums";

@Component({ components: { QuestionText, AdminOptions, MultiDocument } })
export default class Checkboxes extends Vue {
    
    created() {
        const user = store.state.signedInUser;
        if ([UserRole.Admin, UserRole.Cqms].includes(user?.role ?? UserRole.None)) {
            this.canDeleteDocuments = true
        }

        // make sure there's a supplier response item for every response option...
        for(const responseOption of this.question.surveyResponses) {
            if(!this.question.supplierResponses.responseItems.some(r => r.responseID === responseOption.id)) {
                this.question.supplierResponses.responseItems.push(ResponseItem.createNew(responseOption.id, false));
            }
        }
    }

    @Prop({ required: true })
    private question!: SurveyQuestion;

    @Prop({ default: SurveyQuestionOptions.CreateDefault })
    private options!: SurveyQuestionOptions;

    // private get selectedResponseIds(): Array<number> {
    //     return this.question.supplierResponses.responseItems.map(i => i.responseID);
    // }
    // private set selectedResponseIds(ids: Array<number>) {
    //     this.question.supplierResponses.responseItems = ids.map(id => ResponseItem.createNew(+id, true)) ;
    // }

    private hasPopup(response: SurveyResponse): boolean {
        return !utils.isEmptyOrWhitespace(response.helpText);
    }

    private tooltipTargetID(responseID: number): string {
        return "responseInfoTooltipTarget-" + responseID;
    }

    private get isReadOnly(): boolean {
        return this.options.isReadOnly || this.question.supplierResponses.status == ResponseStatus.Accepted
    }
    
    private canDeleteDocuments: boolean = false;

    shouldShowDocumentUpload(response: SurveyResponse) {
        return (response.otherOption === SurveyResponseOtherOptionType.MultiDocument 
            || response.otherOption === SurveyResponseOtherOptionType.Evidence)
            && this.isIdSelected(response.id);
    }

    shouldShowMultiLine(response: SurveyResponse) {
        return (response.otherOption === SurveyResponseOtherOptionType.MultiLine
            || response.otherOption === SurveyResponseOtherOptionType.Evidence) 
            && this.isIdSelected(response.id);
    }

    shouldShowSingleLine(response: SurveyResponse) {
        return response.otherOption === SurveyResponseOtherOptionType.SingleLine 
            && this.isIdSelected(response.id);
    }

    onUploaded(documentToAdd: ModelDocument, tag: string) {
        const responseID = this.responseIdFromTag(tag);
        if(responseID > 0) {
            const item = this.question.supplierResponses.responseItems.find(i => i.responseID === responseID);
            if(!item) return;
            // no point adding ID as it won't have one until it's saved
            item.documents.push(documentToAdd);
        }
        else {
            // no point adding ID as it won't have one until it's saved
            this.question.supplierResponses.documents.push(documentToAdd);
        }
    }

    onRemove(documentToRemove: ModelDocument, tag: string) {
        const responseID = this.responseIdFromTag(tag);
        if(responseID > 0) {
            const item = this.question.supplierResponses.responseItems.find(i => i.responseID === responseID);
            if(!item) return;
            item.documents = item.documents.filter(d => d.filename !== documentToRemove.filename);
            item.documentIDs = item.documentIDs.filter(id => id != documentToRemove.id);
        }
        else {
            this.question.supplierResponses.documents = this.question.supplierResponses.documents.filter(d => d.filename !== documentToRemove.filename);
            this.question.supplierResponses.documentIDs = this.question.supplierResponses.documentIDs.filter(id => id != documentToRemove.id);
        }
    }

    responseIdToTag(id: number): string {
        return `response-id=${id}`;
    }

    responseIdFromTag(tag: string): number {
        if(!tag || !tag.startsWith("response-id=")) return -1;
        return +tag.replace("response-id=", "");
    }

    isIdSelected(responseID: number) {
        for(const item of this.question.supplierResponses.responseItems) {
            if(item.responseID === responseID) return item.isSelected;
        }
        return false;
    }
}
