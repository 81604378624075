import { mapData } from "@/stuff/DataMapping";
import { Document as ModelDocument, IDocument } from "@/model/Document";

export interface IResponseItem {
    responseID: number;
    isSelected: boolean;
    text: string;
    documentIDs: Array<string>;
    documents: Array<IDocument>;
}

export class ResponseItem implements IResponseItem {

    constructor(data?: IResponseItem) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IResponseItem) {
        mapData(data, { 
            root: () => this,
            documents: () => new ModelDocument(),
        });
    }

    responseID: number = 0;
    isSelected: boolean = false;
    text: string = "";
    documentIDs: Array<string> = [];
    documents: Array<ModelDocument> = [];

    static createNew(responseID: number, isSelected: boolean): ResponseItem {
        return new ResponseItem({ 
            responseID: responseID,
            isSelected: isSelected,
            text: "",
            documentIDs: [],
            documents: []
        });
    }
}
