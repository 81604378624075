
import { Component, Prop, Vue } from "vue-property-decorator";
import MatrixCell from "./MatrixCell.vue";
import QuestionText from "./QuestionText.vue";
import AdminOptions from "./AdminOptions.vue";
import { SurveyQuestion } from "@/model/SurveyQuestion";
import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";
import { ResponseStatus } from "@/model/Enums";

@Component({ components: { QuestionText, AdminOptions, MatrixCell } })
export default class Matrix extends Vue {

    @Prop({ required: true })
    private question!: SurveyQuestion;

    @Prop({ default: SurveyQuestionOptions.CreateDefault })
    private options!: SurveyQuestionOptions;

    // mounted() {
    //     for(let r = 0; r < this.question.matrixRows.length; r++)
    //     for(let c = 0; c < this.question.matrixColumns.length; c++)
    //     console.log(`row:${r} col:${c} x:${this.getResponseIndex(r,c)}`)
    // }

    private get isReadOnly(): boolean {
        return this.options.isReadOnly || this.question.supplierResponses.status == ResponseStatus.Accepted
    }

    // get a sequential index from row and column indeces
    getResponseIndex(rowIdx: number, colIdx: number): number {
        const combinedIndex = (rowIdx * this.question.matrixColumns.length) + colIdx;
        while(this.question.supplierResponses.matrixResponses.length < (combinedIndex + 1)) {
            this.question.supplierResponses.matrixResponses.push(null);
        }
        return combinedIndex;
    }
}
