
import { Component, Prop, Vue } from "vue-property-decorator";
import { SurveyQuestion } from "@/model/SurveyQuestion";
import utils from "@/stuff/Utils";

@Component
export default class NoResponse extends Vue {

    @Prop({ required: true })
    private question!: SurveyQuestion;

    private get hasPopup(): boolean {
        return !utils.isEmptyOrWhitespace(this.question.popupText);
    }

    private get tooltipTargetID(): string {
        return "infoTooltipTarget-" + this.question.id;
    }

    private get visibleToBuyerTargetID(): string {        
        return "vtb-" + this.question.id;
    }
}
