import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { Document as ModelDocument, IDocument } from "@/model/Document";

export interface IModule {
    id: number;
    displayOrder: number;
    description: string;
    isBuyerLevel: boolean;
    isMandatory: boolean;
    tradeIDs: Array<number>;
    scopeIDs: Array<number>;
    isDocument: boolean;
    isCoinsInformationSet: boolean;
    cdmCategoryID: number;
    guideDocumentID: string;
    documentIDs: Array<string>;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    exemptFromCompliance: boolean;
    responseSetID: number;
    guideDocument: IDocument;
    documents: Array<IDocument>;
    chaseEmails: Array<number>;
    rejectionChaseEmails: Array<number>;
    hasChaseEmails: boolean;
    rejectionAdminActionRequired: boolean;
    acceptedAdminActionRequired: boolean;
    exemptFromQA: boolean;
    confirmOnSubmission: boolean;
}

export class Module implements IModule {

    constructor(data?: IModule) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IModule) {
        mapData(data, { 
            root: () => this,
            documents: () => new ModelDocument(),
            guideDocument: () => this.guideDocument
        });
    }

    id: number = 0;
    displayOrder: number = 0;
    description: string = "";
    isBuyerLevel: boolean = false;
    isMandatory: boolean = false;
    tradeIDs: Array<number> = [];
    scopeIDs: Array<number> = [];
    isDocument: boolean = false;
    isCoinsInformationSet: boolean = false;
    cdmCategoryID: number = 0;
    guideDocumentID: string = "";
    documentIDs: Array<string> = [];
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;
    exemptFromCompliance: boolean = false;
    responseSetID: number = 0;
    guideDocument: ModelDocument = new ModelDocument();
    documents: Array<ModelDocument> = [];
    chaseEmails: Array<number> = [];
    rejectionChaseEmails: Array<number> = [];
    hasChaseEmails: boolean = false;
    rejectionAdminActionRequired: boolean = false;
    acceptedAdminActionRequired: boolean = false;
    exemptFromQA: boolean = false;
    confirmOnSubmission: boolean = false;
    
    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get isDeleted() {
        return utils.hasDateValue(this.deleted);
    }
}
