import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { SurveyPage, ISurveyPage } from './SurveyPage';
import { SurveySection } from './SurveySection';
import { SurveyQuestion } from './SurveyQuestion';
import { SurveyResponse } from './SurveyResponse';

export interface ISurvey {
    id: number;
    moduleID: number;
    description: string;
    instructions: string;
    published: Date;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;

    surveyPages: Array<ISurveyPage>;
}

export class Survey implements ISurvey {

    constructor(data?: ISurvey) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ISurvey) {
        if(!data) {
            console.warn("Model.Survey.Update - data is not set");
            return;
        }
        mapData(data, { 
            root: () => this,
            surveyPages: () => new SurveyPage(),
            surveySections: () => new SurveySection(),
            surveyQuestions: () => new SurveyQuestion(),
            surveyResponses: () => new SurveyResponse()
        });
    }

    id: number = 0;
    moduleID: number = 0;
    description: string = "";
    instructions: string = "";
    published: Date = new Date(utils.emptyDateValue);
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;

    surveyPages: Array<SurveyPage> = [];

    get isNew() {
        return utils.isEmptyId(this.id);
    }
}
