import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { Document as ModelDocument, IDocument } from "@/model/Document";
import { HistoryItemType, ResponseSetStatus } from "./Enums";
import { HistoryItem } from "./HistoryItem";
import { ChaseHistory } from "./ChaseHistory";

export interface ISupplierResponseSet {
    id: number;
    supplierID: number;
    supplierName: string;
    surveyID: number;
    documentIDs: Array<string>;
    schemeNameID: number;
    siteID: number;
    activeFrom: Date | null;
    requestWhen: Date | null;
    approved: Date | null;
    expires: Date | null;
    lastChased: Date | null;
    assignedToUserID: number;
    status: ResponseSetStatus;
    legacyProgressID: number;
    rejectReason: string;
    trade: string;
    companyLegalName: string;
    numberOfEmployees: number;
    numberOfSubcontractors: number;
    notes: string;
    fastTrack: boolean;
    newSupplier: boolean;
    history: Array<HistoryItem>;
    chaseHistory: Array<ChaseHistory>;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    dts: boolean;

    questionCountNone: number;
    questionCountHidden: number;
    questionCountEntered: number;
    questionCountRejected: number;
    questionCountAccepted: number;
    questionCount: number;
    responseCount: number;

    documents: Array<IDocument>;

    site: string;
}

export class SupplierResponseSet implements ISupplierResponseSet {

    constructor(data?: ISupplierResponseSet) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ISupplierResponseSet) {
        mapData(data, { 
            root: () => this,
            documents: () => new ModelDocument(),
            history: () => new HistoryItem(),
            chaseHistory: () => new ChaseHistory()
        });
    }

    id: number = 0;
    moduleID: number = 0;
    surveyID: number = 0;
    supplierID: number = 0;
    supplierName: string = "";
    documentIDs: Array<string> = [];
    schemeNameID: number = 0;
    siteID: number = 0;
    activeFrom: Date | null = new Date(utils.emptyDateValue);
    requestWhen: Date | null = new Date(utils.emptyDateValue);
    approved: Date | null = new Date(utils.emptyDateValue);
    expires: Date | null = new Date(utils.emptyDateValue);
    lastChased: Date | null = new Date(utils.emptyDateValue);
    assignedToUserID: number = 0;
    status: ResponseSetStatus = ResponseSetStatus.None;
    legacyProgressID: number = 0;
    rejectReason: string = "";
    acceptReason: string = "";
    trade: string = "";
    companyLegalName: string = "";
    numberOfEmployees: number = 0;
    numberOfSubcontractors: number = 0;
    notes: string = "";
    fastTrack: boolean = false;
    newSupplier: boolean = false;
    history: Array<HistoryItem> = [];
    chaseHistory: Array<ChaseHistory> = [];
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;
    dts: boolean = false;

    questionCountNone: number = 0;
    questionCountHidden: number = 0;
    questionCountEntered: number = 0;
    questionCountRejected: number = 0;
    questionCountAccepted: number = 0;
    questionCount: number = 0;
    responseCount: number = 0;

    documents: Array<ModelDocument> = [];

    declarationName: string|null = null;
    declarationJobRole: string|null = null;
    detailsCorrect: boolean|null = null;

    site: string = "";

    get isCurrent() {
        return !utils.hasDateValue(this.expires) || 
               this.expires == null || 
               this.expires > utils.today();
    }

    get isInPlay() {
        return this.status === ResponseSetStatus.AwaitingCompletion || 
               this.status === ResponseSetStatus.Submitted ||
               this.status === ResponseSetStatus.Rejected ||
               (this.isLegacy && this.legacyProgressID > 0);
    }

    get isLegacy() {
        return this.status === ResponseSetStatus.Legacy || this.status === ResponseSetStatus.LegacyInProgress;
    }

    get canDelete() {
        if(this.history == null) {
            console.warn("SupplierResponseSet.canDelete - history is NULL!")
            return false; // fail-safe
        } 
        // can only delete if no responses added
        if(this.history.some(h => h.type === HistoryItemType.FirstResponses)) return false;
        return true;
    }

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get treeKey() {
        return `rset:${this.id}`;
    }

    get hasExpiry(): boolean {
        return utils.hasDateValue(this.expires)
    }
    
    get expired(): boolean {
        return utils.hasDateValue(this.expires) && this.expires! < new Date();
    }
}
