
import utils from "@/stuff/Utils";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ })
export default class MatrixCell extends Vue {

    @Prop({ required: true })
    private value!: number|null;

    @Prop({ default: true })
    private disabled!: boolean;

    private updateValue(value: string) {
        const numberValue = utils.hasNumericValue(value) ? +value : null;
        console.log(`matrix cell update raw=${value} numberValue=${numberValue}`);
        this.$emit("input", numberValue);
    }
}
