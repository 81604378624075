
import Vue from "vue";
import Component from "vue-class-component";
import SlideUpDown from "vue-slide-up-down";
import { Prop } from "vue-property-decorator";
import eventBus from "@/stuff/EventBus";
import ApiButton from "@/components/ApiButton.vue";
import utils from "@/stuff/Utils";
import { Validations } from "vuelidate-property-decorators";
import { SurveyQuestion } from "@/model/SurveyQuestion";
import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";
import { ResponseSetStatus, ResponseStatus } from "@/model/Enums";
import { LookupItem } from "@/model/LookupItem";

@Component({ components: { ApiButton, SlideUpDown }})
export default class SingleLineText extends Vue {

    mounted() {
        eventBus.$on("touch-to-validate", () => {
            if(this.$v == null) return;
            this.$v.$touch();
            // Nooo! invalid stuff might be on OTHER page that is not visible so cannot rely on this
            //this.question.supplierResponses.failedValidation = !this.$v.responseText.$invalid;
        });
        [...document.getElementsByClassName("resize_textarea")].forEach(e => this.resizeTextarea(e as HTMLElement));
    }

    @Prop({ required: true })
    private question!: SurveyQuestion;

    @Prop({ default: SurveyQuestionOptions.CreateDefault })
    private options!: SurveyQuestionOptions;

    private isHistoryListExpanded: boolean = false;

    // need to expose enum via a property for it to be accessible via template!
    private get StatusEnum(): typeof ResponseStatus {
        return ResponseStatus;
    }

    private get userList(): Array<LookupItem> { return this.$store.state.userList; }

    private get supplierResponseStatus(): ResponseStatus {
        if(!this.question.supplierResponses) return ResponseStatus.None;
        return this.question.supplierResponses.status;
    }
    private set supplierResponseStatus(value: ResponseStatus) {
        if(!this.question.supplierResponses) {
            console.warn("Question has no response!");
            return;
        }
        this.question.supplierResponses.status = value;
    }

    // hide box unless admin - or supplier and is rejected
    private get shouldShowAny(): boolean {
        return this.shouldShowHistory || this.shouldShowSupplierRejectReason || this.shouldShowSupplierAcceptComments || this.shouldShowAcceptRejectOptions;
    }

    private get shouldShowHistory(): boolean {
        // - hide if question is exempt from approval
        // - show to supplier if rejected
        return !this.question.isExemptFromApproval && (this.options.isAdmin || this.options.responseSetStatus === ResponseSetStatus.Rejected);
    }

    private get isAnyHistory(): boolean {
        if(!this.question.supplierResponses) return false;
        if(!this.question.supplierResponses.history) false;
        return this.question.supplierResponses.history.length > 0;
    }

    private get historyBadge(): string {
        if(!this.question.supplierResponses) return "-";
        if(!this.question.supplierResponses.history) return "-";
        return this.question.supplierResponses.history.length.toString();
    }

    private get shouldShowSupplierRejectReason(): boolean {
        //supplier and whole rejected and is *this* rejected
        return !this.options.isAdmin && 
            this.options.responseSetStatus === ResponseSetStatus.Rejected &&
            this.question.supplierResponses.status === ResponseStatus.Rejected;
    }

    private get shouldShowSupplierAcceptComments(): boolean {
        return !this.options.isAdmin && 
            this.question.supplierResponses.status === ResponseStatus.Accepted &&
            !utils.isEmptyOrWhitespace(this.question.supplierResponses.acceptComments);
    }

    private get shouldShowAcceptRejectOptions(): boolean {
        return this.options.isAdmin && !this.question.isExemptFromApproval;
    }

    private get cannotAcceptOrReject(): boolean {
        return !this.options.isAdmin || this.options.responseSetStatus !== ResponseSetStatus.Submitted;
    }

    private get alertCssClass(): string {
        let variant = "info";
        if (this.supplierResponseStatus === ResponseStatus.Rejected) variant = "danger";
        if (this.supplierResponseStatus === ResponseStatus.Entered) variant = "warning";
        return `alert alert-${variant} mt-3`;
    }

    private get rejectTooltipTargetID(): string {
        return "rejectReason-" + this.question.id;
    }

    private get acceptTooltipTargetID(): string {
        return "acceptComments-" + this.question.id;
    }


    //
    // -- methods
    //

    private toggleHistoryList() {
        this.isHistoryListExpanded = !this.isHistoryListExpanded
    }

    private resizeTextarea(field: HTMLElement) {        
        // Reset field height
        field.style.height = 'inherit';

        // Get the computed styles for the element
        const computed = window.getComputedStyle(field);

        // Calculate the height
        const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                    + parseInt(computed.getPropertyValue('padding-top'), 10)
                    + field.scrollHeight
                    + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                    + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

        field.style.height = height + 'px';
    }

    //
    // -- validation - just for UI - we have to run a validation on all the responses before saving
    //                 and this only covers the visible ones!
    //

    @Validations()
    validations() {
        return {
            responseText: { 
                isValid: () => {
                    if(this.question == null) return true;
                    if(this.question.supplierResponses == null) return true;
                    if(this.question.supplierResponses.status !== ResponseStatus.Rejected) return true;
                    return !utils.isEmptyOrWhitespace(this.question.supplierResponses.rejectReason);
                }
            }
        };
    }


}
