
import { Component, Prop, Vue } from "vue-property-decorator";
import QuestionText from "@/components/Survey/QuestionText.vue";
import MultiDocument from "@/components/MultiDocument.vue";
import AdminOptions from "./AdminOptions.vue";
import { Validations } from "vuelidate-property-decorators";
import { SurveyQuestion } from "@/model/SurveyQuestion";
import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";
import { Document as ModelDocument } from "@/model/Document";
import { ResponseStatus, UserRole } from "@/model/Enums";
import store from "@/store/store";

@Component({ components: { QuestionText, MultiDocument, AdminOptions } })
export default class Evidence extends Vue {
    
    mounted() {
        const user = store.state.signedInUser;
        if ([UserRole.Admin, UserRole.Cqms].includes(user?.role ?? UserRole.None)) {
            this.canDeleteDocuments = true
        }
    }

    @Prop({ required: true })
    private question!: SurveyQuestion;

    @Prop({ default: SurveyQuestionOptions.CreateDefault })
    private options!: SurveyQuestionOptions;

    errorMessage = ""; 
    private canDeleteDocuments: boolean = false;

    private get tooltipTargetID(): string {
        return "tooltipTarget-" + this.question.id;
    }

    private get isReadOnly(): boolean {
        return this.options.isReadOnly || this.question.supplierResponses.status == ResponseStatus.Accepted
    }

    // mounted() {
    // }

    onUploaded(documentToAdd: ModelDocument) {
        this.question.supplierResponses.documents.push(documentToAdd);
        // no point adding ID as it won't have one until it's saved
    }

    onRemove(documentToRemove: ModelDocument) {
        this.question.supplierResponses.documents = this.question.supplierResponses.documents.filter(d => d.filename !== documentToRemove.filename);
        this.question.supplierResponses.documentIDs = this.question.supplierResponses.documentIDs.filter(id => id != documentToRemove.id);
    }

    // ????
    @Validations()
    validations() {
        return {
            responseText: { 
                isValid: () => true
            }
        };
    }
}
