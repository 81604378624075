import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { HistoryItemType } from "./Enums";

export interface IHistoryItem {   
    created: Date;
    createdByUserID: number;
    type: HistoryItemType;
    extra: string;
}

export class HistoryItem implements IHistoryItem {

    constructor(data?: IHistoryItem) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IHistoryItem) {
        mapData(data, { root: () => this });
    }

    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    type: HistoryItemType = HistoryItemType.None ;
    extra: string = "";

    get description(): string {
        if(!utils.isEmptyOrWhitespace(this.extra)) return this.extra;
        if(this.type === HistoryItemType.FirstResponses) return "Response(s) added";
        return HistoryItemType[this.type];
    }
}
