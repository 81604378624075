import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { Document as ModelDocument, IDocument } from "@/model/Document";
import { ResponseStatus } from "./Enums";
import { HistoryItem, IHistoryItem } from "@/model/HistoryItem";
import { IResponseItem, ResponseItem } from "@/model/ResponseItem";

export interface ISupplierResponses {
    supplierResponseSetID: number;
    surveyQuestionID: number;
    responseItems: Array<IResponseItem>;
    textResponse: string;
    dateResponse: Date;
    numberResponse: number|null;
    currencyCode: string|null;
    matrixResponses: Array<number|null>;
    documentIDs: Array<string>;   
    status: ResponseStatus;
    rejectReason: string;
    acceptComments: string;
    history: Array<IHistoryItem>;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    visibleToBuyers: string;
    documents: Array<IDocument>;
}

export class SupplierResponses implements ISupplierResponses {

    constructor(data?: ISupplierResponses) {
        if (typeof data === "undefined" || data === null) {
            return;
        }
        this.update(data);
    }

    update(data: ISupplierResponses): void {
        mapData(data, { 
            root: () => this,
            responseItems: () => new ResponseItem(),
            documents: () => new ModelDocument(),
            history: () => new HistoryItem()
        });
    }

    supplierResponseSetID: number = 0;
    surveyQuestionID: number = 0;
    responseItems: Array<ResponseItem> = [];
    textResponse: string = "";
    dateResponse: Date = new Date(utils.emptyDateValue);
    numberResponse: number|null = 0;
    currencyCode: string|null = "";
    matrixResponses: Array<number|null> = [];
    documentIDs: Array<string> = [];   
    status: ResponseStatus = ResponseStatus.None;
    rejectReason: string = "";
    acceptComments: string = "";
    history: Array<HistoryItem> = [];
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;

    visibleToBuyers: string = "";

    documents: Array<ModelDocument> = [];

    get isNew(): boolean {
        return !utils.hasDateValue(this.created);
    }

    get isVisibleToBuyers(): boolean {
        return !utils.isEmptyOrWhitespace(this.visibleToBuyers)
    }

    get visibleToBuyersText(): string {
        return `<p><b>Please note:</b> The following buyer(s) will be able to see your response to this question:</p><ul style="list-style: none; padding-left: 0; margin-bottom: 5px">${this.visibleToBuyers}</ul>`;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static isASupplierResponse(item: any): boolean {
        if(typeof item !== "object") return false;
        if(typeof item.supplierResponseSetID === "undefined") return false;
        if(typeof item.surveyQuestionID === "undefined") return false;
        return true;
    }
}
