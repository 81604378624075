import { ResponseSetStatus } from "./Enums";

// For passing variables around for rendering survey components
export class SurveyQuestionOptions {

    constructor(isAdmin: boolean = false, status: ResponseSetStatus = ResponseSetStatus.None) {
        this.isAdmin = isAdmin;
        this.responseSetStatus = status;
    }

    isAdmin: boolean = false;
    responseSetStatus: ResponseSetStatus = ResponseSetStatus.None;

    get isReadOnly(): boolean {
        return (this.isAdmin && this.responseSetStatus != ResponseSetStatus.Submitted)  ||
               (!this.isAdmin && (this.responseSetStatus == ResponseSetStatus.Submitted || this.responseSetStatus == ResponseSetStatus.Accepted));
    }

    static CreateDefault(): SurveyQuestionOptions {
        return new SurveyQuestionOptions();
    }
}