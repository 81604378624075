import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { ISurveyResponse, SurveyResponse } from "./SurveyResponse";
import { ISupplierResponses, SupplierResponses } from "./SupplierResponses";
import { SurveyQuestionType } from "./Enums";

export interface ISurveyQuestion {
    id: number;
    surveySectionID: number;
    dependentOnResponseID: number;
    isExemptFromApproval: boolean;
    hideCondition: string;
    description: string;
    extraText: string;
    popupText: string;
    type: SurveyQuestionType;
    shouldHideSeparator: boolean;
    matrixRows: Array<string>;
    matrixColumns: Array<string>;
    displayOrder: number;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;

    surveyResponses: Array<ISurveyResponse>;

    supplierResponses: ISupplierResponses;
}

export class SurveyQuestion implements ISurveyQuestion {

    constructor(data?: ISurveyQuestion) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ISurveyQuestion): void {
        mapData(data, { 
            root: () => this,
            supplierResponses: () => this.supplierResponses,
            surveyResponses: () => new SurveyResponse()
        });
    }

    id: number = 0;
    surveySectionID: number = 0;
    dependentOnResponseID: number = 0;
    isExemptFromApproval: boolean = false;
    hideCondition: string = "";
    description: string = "";
    extraText: string = "";
    popupText: string = "";
    type: SurveyQuestionType = SurveyQuestionType.NoResponse;
    shouldHideSeparator: boolean = false;
    matrixRows: Array<string> = [];
    matrixColumns: Array<string> = [];
    displayOrder: number = 0;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;

    // not from server - see SurveyUtils.updateQuestionDependencies
    shouldHideBasedOnResponse: boolean = false;

    surveyResponses: Array<SurveyResponse> = [];

    supplierResponses: SupplierResponses = new SupplierResponses();
    
    get isNew(): boolean {
        return utils.isEmptyId(this.id);
    }

    get typeText(): string {
        // text from enum - used to choose component to display
        return SurveyQuestionType[this.type];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static isASurveyQuestion(item: any): boolean {
        if(typeof item !== "object") return false;
        if(typeof item.surveySectionID === "undefined") return false;
        return true;
    }
}
