
import { Component, Prop, Vue } from "vue-property-decorator";
import QuestionText from "./QuestionText.vue";
import AdminOptions from "./AdminOptions.vue";
import MultiDocument from "@/components/MultiDocument.vue";
import utils from "@/stuff/Utils";
import { SurveyQuestion } from "@/model/SurveyQuestion";
import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";
import { SurveyResponse } from "@/model/SurveyResponse";
import { Document as ModelDocument } from "@/model/Document";
import { ResponseStatus, SurveyResponseOtherOptionType, UserRole } from "@/model/Enums";
import { ResponseItem } from "@/model/ResponseItem";
import store from "@/store/store";

@Component({ components: { QuestionText, AdminOptions, MultiDocument } })
export default class RadioButtons extends Vue {
    
    mounted() {
        const user = store.state.signedInUser;
        if ([UserRole.Admin, UserRole.Cqms].includes(user?.role ?? UserRole.None)) {
            this.canDeleteDocuments = true
        }
    }

    private canDeleteDocuments: boolean = false;

    @Prop({ required: true })
    private question!: SurveyQuestion;

    @Prop({ default: SurveyQuestionOptions.CreateDefault })
    private options!: SurveyQuestionOptions;

    private get selectedResponseId(): number {
        return this.question.supplierResponses.responseItems.length == 0 
            ? 0 
            : this.question.supplierResponses.responseItems[0].responseID;
    }
    private set selectedResponseId(selectedID: number) {
        this.question.supplierResponses.responseItems = [ ResponseItem.createNew(+selectedID, true)];
    }

    private tooltipTargetID(responseID: number): string {
        return "responseInfoTooltipTarget-" + responseID;
    }

    private get isReadOnly(): boolean {
        return this.options.isReadOnly || this.question.supplierResponses.status == ResponseStatus.Accepted
    }

    private hasPopup(response: SurveyResponse): boolean {
        return !utils.isEmptyOrWhitespace(response.helpText);
    }

    shouldShowDocumentUpload(response: SurveyResponse) {
        return (response.otherOption === SurveyResponseOtherOptionType.MultiDocument 
            || response.otherOption === SurveyResponseOtherOptionType.Evidence)
            && this.isIdSelected(response.id);
    }

    shouldShowMultiLine(response: SurveyResponse) {
        return (response.otherOption === SurveyResponseOtherOptionType.MultiLine 
            || response.otherOption === SurveyResponseOtherOptionType.Evidence) 
            && this.isIdSelected(response.id);
    }

    shouldShowSingleLine(response: SurveyResponse) {
        return response.otherOption === SurveyResponseOtherOptionType.SingleLine 
            && this.isIdSelected(response.id);
    }

    onUploaded(documentToAdd: ModelDocument) {
        console.log("onUploaded", documentToAdd);
        this.question.supplierResponses.documents.push(documentToAdd);
        // no point adding ID as it won't have one until it's saved
    }

    onRemove(documentToRemove: ModelDocument) {
        console.log("onRemoved - doc", documentToRemove);
        console.log("onRemoved - before", this.question.supplierResponses.documentIDs);
        this.question.supplierResponses.documents = this.question.supplierResponses.documents.filter(d => d.filename !== documentToRemove.filename);
        this.question.supplierResponses.documentIDs = this.question.supplierResponses.documentIDs.filter(id => id != documentToRemove.id);
        console.log("onRemoved - after", this.question.supplierResponses.documentIDs);
    }

    isIdSelected(responseID: number) {
        for(const item of this.question.supplierResponses.responseItems) {
            if(item.responseID === responseID) return true;
        }
        return false;
    }
}
