
import { Component, Prop, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import DatePickerComponent from "@/components/DatePicker.vue";
import QuestionText from "./QuestionText.vue";
import AdminOptions from "./AdminOptions.vue";
import { SurveyQuestion } from "@/model/SurveyQuestion";
import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";
import { ResponseStatus } from "@/model/Enums";

@Component({ components: { QuestionText, AdminOptions, DatePickerComponent } })
export default class DatePicker extends Vue {

    @Prop({ required: true })
    private question!: SurveyQuestion;

    @Prop({ default: SurveyQuestionOptions.CreateDefault })
    private options!: SurveyQuestionOptions;

    errorMessage = "";

    private get tooltipTargetID(): string {
        return "tooltipTarget-" + this.question.id;
    }

    private get isReadOnly(): boolean {
        return this.options.isReadOnly || this.question.supplierResponses.status == ResponseStatus.Accepted
    }

    @Validations()
    validations() {
        return {
            responseText: { 
                isValid: () => true
            }
        };
    }
}
