import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { ISurveySection, SurveySection } from './SurveySection';

export interface ISurveyPage {
    id: number;
    surveyID: number;
    displayOrder: number;
    hideCondition: string;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;

    surveySections: Array<ISurveySection>;
}

export class SurveyPage implements ISurveyPage {

    constructor(data?: ISurveyPage) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ISurveyPage) {
        mapData(data, { 
            root: () => this,
            surveySections: () => new SurveySection()
        });
    }

    id: number = 0;
    surveyID: number = 0;
    displayOrder: number = 0;
    hideCondition: string = "";
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;

    // not from server - see SurveyUtils.updateQuestionDependencies
    shouldHideBasedOnResponse: boolean = false;

    surveySections: Array<SurveySection> = [];

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static isASurveyPage(item: any): boolean {
        if(typeof item !== "object") return false;
        if(typeof item.surveyID === "undefined") return false;
        if(typeof item.surveySections === "undefined") return false;
        return true;
    }
}
