import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { ISurveyQuestion, SurveyQuestion } from './SurveyQuestion';
import { SurveySectionType } from './Enums';

export interface ISurveySection {
    id: number;
    surveyPageID: number;
    hideCondition: string;
    description: string;
    extra: string;
    type: SurveySectionType;
    displayOrder: number;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;

    surveyQuestions: Array<ISurveyQuestion>;
}

export class SurveySection implements ISurveySection {

    constructor(data?: ISurveySection) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ISurveySection) {
        mapData(data, { 
            root: () => this,
            surveyQuestions: () => new SurveyQuestion()
        });
    }

    id: number = 0;
    surveyPageID: number = 0;
    hideCondition: string = "";
    description: string = "";
    extra: string = "";
    type: SurveySectionType = SurveySectionType.Default;
    displayOrder: number = 0;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;

    // not from server - see SurveyUtils.updateQuestionDependencies
    shouldHideBasedOnResponse: boolean = false;

    surveyQuestions: Array<SurveyQuestion> = [];

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get typeText() {
        return SurveySectionType[this.type];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static isASurveySection(item: any): boolean {
        if(typeof item !== "object") return false;
        if(typeof item.surveyPageID === "undefined") return false;
        if(typeof item.surveyQuestions === "undefined") return false;
        return true;
    }
}
