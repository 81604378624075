
import { Component, Prop, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import QuestionText from "./QuestionText.vue";
import AdminOptions from "./AdminOptions.vue";
import * as toastr from "toastr";
import { SurveyQuestion } from "@/model/SurveyQuestion";
import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";
import { ResponseStatus } from "@/model/Enums";

@Component({ components: { QuestionText, AdminOptions } })
export default class FreeText extends Vue {

    @Prop({ required: true })
    private question!: SurveyQuestion;

    @Prop({ default: SurveyQuestionOptions.CreateDefault })
    private options!: SurveyQuestionOptions;

    errorMessage = ""; 

    private get tooltipTargetID(): string {
        return "tooltipTarget-" + this.question.id;
    }

    private get isReadOnly(): boolean {
        return this.options.isReadOnly || this.question.supplierResponses.status == ResponseStatus.Accepted
    }

    // just for test
    save() {
        this.$v.$touch();
        if (this.$v.$invalid) {
            toastr.info("Please fix the highlighted errors", "Validation errors");
        }
    }

    @Validations()
    validations() {
        return {
            responseText: { 
                isValid: () => {
                    if(this.question.supplierResponses.textResponse !== "blee") {
                        this.errorMessage = "";
                        return true;
                    }
                    else {
                        this.errorMessage = "Cannot be blee.";
                        return false;
                    }
                }
            }
        };
    }
}
