
import { Component, Prop, Vue } from "vue-property-decorator";
import { SurveyQuestion } from "@/model/SurveyQuestion";
import QuestionText from "./QuestionText.vue";

@Component({ components: { QuestionText } })
export default class NoResponse extends Vue {
    @Prop({ required: true })
    private question!: SurveyQuestion;

}
