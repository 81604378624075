import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { SurveyResponseOtherOptionType } from "@/model/Enums";

export interface ISurveyResponse {
    id: number;
    surveyQuestionID: number;
    description: string;
    helpText: string;
    helpTextIsImportant: boolean;
    otherOption: SurveyResponseOtherOptionType;
    scopeIDsJson: Array<number>;
    tradeIDsJson: Array<number>;
    displayOrder: number;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
}

export class SurveyResponse implements ISurveyResponse {

    constructor(data?: ISurveyResponse) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ISurveyResponse) {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    surveyQuestionID: number = 0;
    description: string = "";
    helpText: string = "";
    helpTextIsImportant: boolean = false;
    otherOption: SurveyResponseOtherOptionType = SurveyResponseOtherOptionType.None;
    scopeIDsJson: Array<number> = [];
    tradeIDsJson: Array<number> = [];
    displayOrder: number = 0;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;

    get isNew() {
        return utils.isEmptyId(this.id);
    }
}
