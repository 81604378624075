import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";

export interface IChaseHistory {
    responseSetID: number;
    chaseEmailID: number;
    notes: string;
    createdDate: Date;
    createdByUserID: number;
}

export class ChaseHistory implements IChaseHistory {

    constructor(data?: IChaseHistory) {
        if (data) this.update(data);
    }

    update(data: IChaseHistory) {
        mapData(data, { root: () => this });
    }

    responseSetID: number = 0;
    chaseEmailID: number = 0;
    notes: string = "";
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;

}